<template>
  <div class="lawyers-to-assign p-2">
    <p class="font-bold mb-2">Lawyers Available</p>
    <div class="search-bar mb-2">
      <fd-input
        v-model="lawyerSearch"
        label=""
        name="lawyerSearch"
        placeholder="Search Lawyer"
        type="text"
        @keyup="onSearchInput"
      >
      </fd-input>
    </div>
    <!-- List  -->
    <div v-if="isLoading" class="list px-2 py-1">
      <lawyer
        isLoading
        :lawyer="{ name: '', bank: '' }"
        v-for="n in 4"
        :key="n"
      ></lawyer>
    </div>
    <div v-else class="list py-1">
      <div v-if="lawyers.length < 1" class="lawyer-empty">
        <NoData message="No lawyers available"></NoData>
      </div>
      <DynamicScroller v-else :items="lawyers" :min-item-size="120">
        <template v-slot="{ item, active }">
          <DynamicScrollerItem
            :item="item"
            :active="active"
            :data-index="item.id"
            :size-dependencies="[item.name, item.bank]"
            class="px-2"
          >
            <div v-if="hasLawyerSlot">
              <slot name="lawyer" :lawyer="item"></slot>
            </div>
            <lawyer
              v-else
              isAssign
              :lawyer="item"
              @assign="
                (lawyer) => {
                  $emit('assign', lawyer);
                }
              "
            >
            </lawyer>
          </DynamicScrollerItem>
        </template>
      </DynamicScroller>
      <InfiniteLoading @infinite="infiniteScrollHandler">
        <span slot="no-more"></span>
      </InfiniteLoading>
    </div>
  </div>
</template>

<script>
import { DynamicScroller, DynamicScrollerItem } from "vue-virtual-scroller";
import InfiniteLoading from "vue-infinite-loading";

import "vue-virtual-scroller/dist/vue-virtual-scroller.css";

import debounce from "lodash/debounce";

export default {
  components: {
    DynamicScroller,
    DynamicScrollerItem,
    InfiniteLoading,
    Lawyer: () => import("./Lawyer"),
    NoData: () => import("@/components/GlobalComponents/NoData/NoData")
  },
  mixins: [],
  props: {
    lawyers: {
      type: Array,
      default: () => []
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      lawyerSearch: ""
    };
  },
  computed: {
    hasLawyerSlot() {
      return this.$scopedSlots.lawyer || this.$slots.lawyer;
    }
  },
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    infiniteScrollHandler(state) {
      this.$emit("infinite-scroll", state);
    },
    onSearchInput: debounce(function (search) {
      this.$emit("search", search);
    }, 300)
  }
};
</script>

<style lang="scss">
.lawyers-to-assign {
  .list {
    max-height: 300px;
    overflow-y: auto;
  }
}
</style>
